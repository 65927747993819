import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const formRef = useRef(null);
  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbzXDAPzDhM72VP6Eb3Tpl46tWbKWdkUsh-u1bSN4hTAiosgxHqc_6zhvPNgFXF_oYgOJg/exec";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
      toast.error("Please fill all fields");
      return;
    }

    setSending(true);

    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    fetch(scriptUrl, {
      mode: "no-cors",
      method: "POST",
      body: formData,
    })
      .then((res) => {
        setSending(false);
        unfill();
        toast.success("Message Sent");
      })
      .catch((err) => {
        setSending(false);
        console.log(err);
        toast.error("error occured, try later");
      });
  };

  function unfill() {
    setName("");
    setEmail("");
    setMessage("");
  }

  return (
    <div className="contact-container">
      <ToastContainer position="bottom-right" />
      <h1>
        <FormattedMessage id="contact.title" />
      </h1>
      <form
        method="post"
        ref={formRef}
        onSubmit={handleSubmit}
        name="google-sheet"
      >
        <div className="fields-2">
          <input
            type="text"
            className="contact-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            placeholder="Your Name *"
          />
          <input
            type="email"
            className="contact-input"
            name="email"
            placeholder="Your Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="fields-1">
          <textarea
            className="contact-input"
            name="message"
            placeholder="your message *"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="form-style">
          <input
            type="submit"
            className={sending ? "submit disabled" : "submit"}
            name="submit"
            value={sending ? "Sending ..." : "Send"}
          />
        </div>
      </form>
      <div className="contact-infos">
        <div className="contact-info">
          <FontAwesomeIcon className="contact-icon" icon={faMailBulk} />
          <label>Oumansourfx200@gmail.com</label>
        </div>
        <div className="contact-info">
          <FontAwesomeIcon className="contact-icon" icon={faPhone} />
          <label>+212 661 961 248</label>
        </div>
      </div>
    </div>
  );
}

export default Contact;
