import React from "react";
import profile from "../images/imad.jpg";

function FounderCard(props) {
  return (
    <div className="founder-card">
      <div className="top-card" />
      <img src={require(`../images/${props.image}`)} alt="profile" className="founder-pic" />
      <div className="bottom-card">
        <h1 className="founder-name">{props.name}</h1>
        <p className="founder-desc">
          {props.desc}
        </p>
      </div>
    </div>
  );
}

export default FounderCard;
