import React from "react";
import { FormattedMessage } from "react-intl";
import FounderCard from "./FounderCard";

function About() {
  return (
    <div className="results-container">
      <div className="result-section calcul8h-s1">
        <div className="overlay2 overlay-chess overlay-about">
          <h1 className="section-head-title">
            <FormattedMessage id="about.title" />
          </h1>
          <p className="about-text">
            <FormattedMessage id="about.text" />
          </p>
        </div>
      </div>

      <div className="result-section calcul8h-s2">
        <div className="overlay3 overlay-chess">
          <h1 className="section-head-title">
            <FormattedMessage id="founders.title" />
          </h1>
          <div className="founders-container">
            <FounderCard
              className="oumansour-card"
              name="Mohammed Al-Mahdi Oumansour"
              desc="He is a currency trader and financial market analyst with more thanseven years of experience in the field of trading. He possesses high-level analytical skills and naked chart analysis, price action trading, and treating the psychological traders and all the stages they go through to write entitled (Trading is Like Chess), which is He invented method with the help of his sister and partner, Jawad Abrai."
              image="mohammed.jpg"
            />
            <FounderCard
              className="jawad-card"
              name="Jaouad Abaray"
              desc="He is a trader and analyst for the financial markets. His story began before Corona. He used this period to work hard for days and nights to reach his result now. His experience is more than four years in the field of trading. He has high experience in analyzing the naked chart, which is price movement, managing companies’ accounts, and risk management, as well as Partner and founder of Oumansour Academy"
              image="jawad.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
