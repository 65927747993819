import { faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function OneToOne() {
  const registrationLink = "https://docs.google.com/forms/d/1Ik7ktWA4KgYwku5ycifQzubnXZX10STTvUEbqmOdMn0/edit";
  return (
    <div className="results-container">
      <div className="result-section calcul8h-s1">
        <div className="overlay2 overlay-chess">
          <h1 className="section-head-title">
            <FormattedMessage id="onetoone.title" />
          </h1>
          <div className="one-to-one-container">
            <Link
              to={registrationLink}
              target="_blank"
              className="register-btn"
            >
              <FontAwesomeIcon icon={faHandPaper} />
              Join The Wait List
            </Link>
            <p className="onetoone-desc">
              <FormattedMessage id="onetoone.text" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneToOne;
