import React from "react";
import Results from "./Results";
import sentence from "../images/tradingislikechess.png";
import Feedbacks from "./Feedbacks";

function Home() {
  return (
    <div className="div-container">
      <div className="home-container">
        <img className="hero-sentence" alt="hero" src={sentence} />
        <p>#Trading is an art</p>
      </div>
      <div id="results">
        <Results />
      </div>
      <div id="feedbacks">
        <Feedbacks />
      </div>
    </div>
  );
}

export default Home;
