import {
  faBars,
  faCaretDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import youtube from "../images/ytb.png";
import telegram from "../images/telegram.webp";
import discord from "../images/discord.png";
import king from "../images/king.png";
import { FormattedMessage } from "react-intl";

import englishFlag from "../images/english.png";
import frenchFlag from "../images/french.png";
import arabicFlag from "../images/arabic.jpg";

function Navbar(props) {
  const youtubeLink = "https://www.youtube.com/@mm_oumansour";
  const telegramLink = "https://t.me/oumansourformation";
  const discordLink = "https://discord.gg/HShjgz2uQ2";
  const [isScrolling, setIsScrolling] = useState(false);
  const [menuIcon, setMenuIcon] = useState(faBars);

  function handleMenuClick() {
    if (menuIcon === faBars) {
      setMenuIcon(faXmark);
      document.getElementById("menu-mobile").classList.add("show-menu");
    } else {
      setMenuIcon(faBars);
      document.getElementById("menu-mobile").classList.remove("show-menu");
    }
  }
  function hideMenu() {
    setMenuIcon(faBars);
    document.getElementById("menu-mobile").classList.remove("show-menu");
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0 || window.scrollY % window.innerHeight === 0) {
        setIsScrolling(false);
      } else {
        setIsScrolling(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function handle(value) {
    props.handleChangeLocale(value);
    hideLangList();
  }

  function handleLangClick() {
    if (document.getElementById("lang-list").style.display === "flex") {
      document.getElementById("lang-list").style.display = "none";
    } else {
      document.getElementById("lang-list").style.display = "flex";
    }
  }

  function hideLangList() {
    document.getElementById("lang-list").style.display = "none";
  }

  return (
    <div
      id="navbar"
      className={isScrolling ? "navbar with-bg" : "navbar no-bg"}
    >
      <div className="logo">
        <img src={king} alt="king" />
        <label>
          <span>Oumansour</span> <br /> <span>Academy</span>
        </label>
      </div>

      <div className="menu" id="menu">
        <ul className="main-menu">
          <Link onClick={hideMenu} to="/">
            <li>
              <FormattedMessage id="nav.home" />
            </li>
          </Link>
          <Link>
            <li className="dropdown-li mentorship-dropdown">
              <div className="head-li mentorship-li">
                <FormattedMessage id="nav.mentorship" />
                <FontAwesomeIcon icon={faCaretDown} className="caret-down" />
              </div>
              <ul className={isScrolling ? "with-bg" : "no-bg"}>
                <Link onClick={hideMenu} to="/clacul8h">
                  <li>
                    <FormattedMessage id="nav.mentorship.calcul8h" />
                  </li>
                </Link>
                <Link onClick={hideMenu} to="/chessmethod">
                  <li>
                    <FormattedMessage id="nav.mentorship.chessmethod" />
                  </li>
                </Link>
                <Link to="/onetoone" onClick={hideMenu}>
                  <li>
                    <FormattedMessage id="nav.mentorship.onetoone" />
                  </li>
                </Link>
              </ul>
            </li>
          </Link>
          <Link>
            <li className="dropdown-li chanllenges-dropdown">
              <div className="head-li challenges-li">
                <FormattedMessage id="nav.challenges" />
                <FontAwesomeIcon icon={faCaretDown} className="caret-down" />
              </div>
              <ul className={isScrolling ? "with-bg" : "no-bg"}>
                <Link to="/hft" onClick={hideMenu}>
                  <li>HFT</li>
                </Link>
                <Link to="/nonhft" onClick={hideMenu}>
                  <li>Account management</li>
                </Link>
              </ul>
            </li>
          </Link>
          <Link to="/copytrading" onClick={hideMenu}>
            <li>
              <FormattedMessage id="nav.copy" />
            </li>
          </Link>
          <Link to="/about" onClick={hideMenu}>
            <li>
              <FormattedMessage id="nav.about" />
            </li>
          </Link>
          <ul className="select-lang">
            <div className="head-li lang-li" onClick={handleLangClick}>
              <FormattedMessage id="nav.language" />
              <FontAwesomeIcon icon={faCaretDown} className="caret-down" />
            </div>
            <div className="lang-list" id="lang-list">
              <li
                onClick={(e) => {
                  handle("en");
                }}
              >
                <img src={englishFlag} alt="en flag" />
                English
              </li>
              <li
                onClick={(e) => {
                  handle("fr");
                }}
              >
                <img src={frenchFlag} alt="fr flag" />
                Français
              </li>
              <li
                onClick={(e) => {
                  handle("ar");
                }}
              >
                <img src={arabicFlag} alt="ar flag" />
                العربية
              </li>
            </div>
          </ul>
          <div className="socials">
            <Link to={youtubeLink} target="_blank">
              <li>
                <img src={youtube} alt="youtube" className="menu-image" />
              </li>
            </Link>
            <Link to={telegramLink} target="_blank">
              <li>
                <img src={telegram} alt="telegram" className="menu-image" />
              </li>
            </Link>
            <Link to={discordLink} target="_blank">
              <li>
                <img src={discord} alt="discord" className="menu-image" />
              </li>
            </Link>
          </div>
          <Link className="contact-nav-btn" onClick={hideMenu} to="/contact">
            <li>
              <FormattedMessage id="contact.title" />
            </li>
          </Link>
        </ul>
      </div>

      <div className="menu-mobile" id="menu-mobile">
        <ul className="main-menu-mobile">
          <Link onClick={hideMenu} to="/">
            <li>
              <FormattedMessage id="nav.home" />
            </li>
          </Link>
          <Link onClick={hideMenu} to="/clacul8h">
            <li>
              <FormattedMessage id="nav.mentorship.calcul8h" />
            </li>
          </Link>
          <Link onClick={hideMenu} to="/chessmethod">
            <li>
              <FormattedMessage id="nav.mentorship.chessmethod" />
            </li>
          </Link>
          <Link to="/onetoone" onClick={hideMenu}>
            <li>
              <FormattedMessage id="nav.mentorship.onetoone" />
            </li>
          </Link>
          <Link to="/hft" onClick={hideMenu}>
            <li>HFT</li>
          </Link>
          <Link to="/nonhft" onClick={hideMenu}>
            <li>Account management</li>
          </Link>
          <Link to="/copytrading" onClick={hideMenu}>
            <li>
              <FormattedMessage id="nav.copy" />
            </li>
          </Link>
          <Link to="/about" onClick={hideMenu}>
            <li>
              <FormattedMessage id="nav.about" />
            </li>
          </Link>
          <Link className="contact-nav-btn" onClick={hideMenu} to="/contact">
            <li>
              <FormattedMessage id="contact.title" />
            </li>
          </Link>
          <div className="lang-list-mobile">
            <li
              onClick={(e) => {
                handle("en");
              }}
            >
              <img src={englishFlag} alt="en flag" />
            </li>
            <li
              onClick={(e) => {
                handle("fr");
              }}
            >
              <img src={frenchFlag} alt="fr flag" />
            </li>
            <li
              onClick={(e) => {
                handle("ar");
              }}
            >
              <img src={arabicFlag} alt="ar flag" />
            </li>
          </div>
          <div className="socials">
            <Link to={youtubeLink} target="_blank">
              <li>
                <img src={youtube} alt="youtube" className="menu-image" />
              </li>
            </Link>
            <Link to={telegramLink} target="_blank">
              <li>
                <img src={telegram} alt="telegram" className="menu-image" />
              </li>
            </Link>
            <Link to={discordLink} target="_blank">
              <li>
                <img src={discord} alt="discord" className="menu-image" />
              </li>
            </Link>
          </div>
        </ul>
      </div>

      <button onClick={handleMenuClick} className="menu-btn">
        <FontAwesomeIcon icon={menuIcon} className="menu-icon" />
      </button>
    </div>
  );
}

export default Navbar;
