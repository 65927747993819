import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import videoPlaceholder from "../images/calcul8hpart1.jpg";
import videoPlaceholder2 from "../images/calcul8hpart2.jpg";

function Calcul8h() {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const [showPart2, setShowPart2] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowPart2(true);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="results-container">
      <div className="result-section calcul8h-s1">
        <div className="overlay2 overlay-chess">
          <h1 className="section-head-title">
            <FormattedMessage id="calcul8h.title" />
          </h1>
          <p className="results-slogan">
            <FormattedMessage id="calcul8h.part1" />
          </p>
          <div className="caclut8h-part-container caclut8h-part-container-2">
            <div className="video-part-desc-container">
              <p>
                <FormattedMessage id="calcul8h.part1.desc" />
              </p>
            </div>
            <div className="video-part-container">
              {!videoLoaded && (
                <img
                  className="video-placeholder"
                  src={videoPlaceholder}
                  alt="Video Placeholder"
                />
              )}
              <iframe
                title="calcul8h-part1"
                src="https://www.youtube.com/embed/cJf9UPcQWFo"
                frameborder="0"
                allowFullScreen
                onLoad={handleVideoLoad}
                style={{ display: videoLoaded ? "block" : "none" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="result-section calcul8h-s2">
        <div className="overlay3 overlay-chess">
          {showPart2 ? (
            <>
              <p className="results-slogan">
                <FormattedMessage id="calcul8h.part2" />
              </p>
              <div className="caclut8h-part-container">
                <div className="video-part-container video-part-container-2">
                  {!videoLoaded && (
                    <img
                      className="video-placeholder"
                      src={videoPlaceholder2}
                      alt="Video Placeholder"
                    />
                  )}
                  <iframe
                    title="calcul8h-part2"
                    src="https://www.youtube.com/embed/2I0O6J8yltM"
                    frameborder="0"
                    allowFullScreen
                    onLoad={handleVideoLoad}
                    style={{ display: videoLoaded ? "block" : "none" }}
                  ></iframe>
                </div>
                <div className="video-part-desc-container video-part-desc-container-2">
                  <p>
                    <FormattedMessage id="calcul8.part2.desc" />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Calcul8h;
