const messages = {
  en: {
    "nav.home": "Home",
    "nav.about": "About Us",
    "nav.mentorship": "Mentorship",
    "nav.mentorship.calcul8h": "Calcul 8H",
    "nav.mentorship.chessmethod": "Chess Method",
    "nav.mentorship.onetoone": "One To One",
    "nav.challenges": "Challenges",
    "nav.copy": "Copy",
    "nav.solde": "Soldes",
    "nav.language": "Language",
    "results.title": "RESULTS",
    "results.herearesomeresults":
      "HERE ARE SOME RESULTS OF OUMANSOUR ACADEMY STUDENTS",
    "feedbacks.title": "FEEDBACKS",
    "calcul8h.title": "CALCUL 8H",
    "calcul8h.part1": "PART 1 - Use 8h",
    "calcul8h.part2": "PART 2 - Use revers fibo",
    "calcul8h.part1.desc":
      "Firstly: \n Determine the market direction by using the last candle from yesterday at 22:00, which means the market opening time is 06:00 in the morning. \n From 70 to 80 percent It gives us the market direction from 6 AM to 12 PM \n Secondly: \n Know when the market will rebound or set a target",
    "calcul8.part2.desc":
      "This method may seem strange to you \n Not traditional or what I studied \n Before we are talking here about the ratios that each market respects greatly and Know the areas from which it may bounce \n It is easy for a beginner to know how the market moves and what to repeat in a simple way \n Data taken from each market over two years",

    "chess.title": "CHESS METHOD",
    "chess.part1": "PART 1 - Two peaks occurred Tuesday and Thursday",
    "chess.part2": "PART 2 - Golden time",
    "chess.part1.desc":
      "This information will benefit you from the chess method by determining the market direction by a certain percentage, identifying the retracement areas, and even more than that, identifying the real highs and lows present in the market. \nThis makes it easier for any trader to see the market and eliminates many problems",
    "chess.part2.desc":
      "This information will benefit you greatly at all times, even when entering your trades. There are certain times in the market that we call golden times, why!? \nThese times are the best areas to enter and exit trades and know the price easily and as a confirmation for all analyses,For example, let us assume that you woke up in the morning to trade the GBPUSD pair to buy. \nYou bought at 08:00 in the morning, but the price is still falling until 9:30 and changes its direction of buying, or 09:30 is confirmation that GBPUSD will continue its downward trend.Between 08:00 and 09:30, everything was a loss for you Let's also assume that you really know the golden time, which is 09:30. \nThis makes you, but it will not make you enter a long trade with 08:00. Rather, you will wait until 09:30 and find out whether it will be buying or selling",
    "onetoone.title": "ONE TO ONE",
    "onetoone.text":
      "Through your study of the chess method, you gradually change your vision for the market, change your entry areas, and some of the shots that you saw and thought you liked.\nAt this stage you are talking about a much higher level than before.\nWe provide you with more than 20 classes:\n- Market direction from the smallest time frame to the largest time frame\n- Real market structure\n- Trusted entry areas\n- The path of the news and your entry before and after.",

    "about.title": "ABOUT US",
    "about.text":
      "Oumansour Trading Academy begins its journey in 2020 with the aim of correcting all mistakes and clarifying the true vision of global markets,\nTo include more than 800 people in its first year,\nThe expansion of the academy began to spread in most Arab countries with the strategy that it devised within three years, which was called the chess method\nWhat inspired people about it is that it does not exceed less than 10 pips in stop loss\nRather, I collected all the entry points present in the market in its most and least dynamic state\nIt did not stop here, as it worked to give the trader the freedom to enter at any time, so that when you delve deeper into the path of chess, you delve deeper into the real market movement, then you understand when you must enter into a corrective movement and the main movement, as some of the academy’s students have reached this stage of entering a deal. Sell, hit the target, and enter a buy deal again\nHere is the difference and attraction. In most trading strategies, you can enter only one trade. With many complications and confirmations, unlike the chess method, your entry points may be glimpsed from the first glance at the market. This is what prompted foreigners to study it from us.\nRather, teaching it to foreigners played an important role in confirming its credibility\nThe number of students in 2024 will reach more than 8 thousand people.",
    "founders.title": "FOUNDERS",
    "hft.title": "HFT",
    "hft.text":
      "Get the opportunity to pass prop-firm challenges through our specialized High-Frequency Trading (HFT) service. We offer exceptional services for traders aiming to successfully navigate prop-firm challenges and enter the world of high-frequency trading.\nWhat is High-Frequency Trading? High-Frequency Trading is a trading strategy that relies on the use of advanced computers and software to execute trades at incredibly fast speeds in financial markets. This approach allows you to capitalize on small price discrepancies in a very short timeframe.\nOn our website, we provide you with the necessary support and guidance to pass prop-firm challenges using high-frequency trading strategies. Whether you're a beginner in this field or a seasoned professional, our dedicated team offers you the support needed to achieve your trading goals.\nJoin us today and benefit from the expertise and skills we provide to help you succeed in the world of high-frequency trading.",
    "hft.whatpropfirms": "What prop firms we pass?",
    "hft.pricing": "Pricing",
    "hft.certif": "CERTIFICATES",
    "nonhft.title": "ACCOUNT MANAGEMENT",
    "nonhft.text":
      "Benefit from our service in managing prop-firm funded accounts through Expert Advisors (EAs) and get the opportunity to achieve your trading goals with ease and efficiency.\nWe offer prop-firm funded account management service using Expert Advisors (EAs) for traders looking to consistently profit in the financial markets.\nWhat is prop-firm funded account management through Expert Advisors (EAs)?\nProp-firm funded account management through Expert Advisors (EAs) is a process that provides professional traders with the opportunity to manage trading accounts funded by investment firms using automated trading techniques.\nThe Expert Advisor (EA) works to execute trading strategies accurately and efficiently to maximize return on investment for clients.\nOn our website, we provide prop-firm funded account management services through Expert Advisors (EAs) with professionalism and reliability.\nWhether you're looking to improve your trading performance or achieve exceptional financial returns, our specialized team provides you with the support and guidance needed to achieve your goals.\nJoin us today and benefit from our expertise in prop-firm funded account management through Expert Advisors (EAs) to succeed in the financial markets.",
    "contact.title": "CONTACT US",
    "contact.name.placeholder": "Your name *",
    "contact.email.placeholder": "Your email *",
    "contact.message.placeholder": "Your message *",
    "contact.submit.text": "Send",
    "copy.title": "COPY TRADING",
    "copy.herearesomeresults": "HERE ARE SOME SCREENS OF COPY TRADING",
    "hft.pay":
      "Please note that payment is required prior to filling out the form, and the challenge to be MT4 not MT5. Thank you for your cooperation",
  },
  fr: {
    "nav.home": "Acceuil",
    "nav.about": "A propos",
    "nav.mentorship": "Mentorat",
    "nav.mentorship.calcul8h": "Calcul 8H",
    "nav.mentorship.chessmethod": "Chess Method",
    "nav.mentorship.onetoone": "One To One",
    "nav.challenges": "Challenges",
    "nav.copy": "Copy",
    "nav.solde": "Soldes",
    "nav.language": "Langue",
    "results.title": "RESULTATS",
    "results.herearesomeresults":
      "VOICI QUELQUES RÉSULTATS DES ÉTUDIANTS DE L'ACADÉMIE OUMANSOUR",
    "feedbacks.title": "COMMENTAIRES",
    "calcul8h.title": "CALCUL 8H",
    "calcul8h.part1": "PARTIE 1 - Utiliser 8h",
    "calcul8h.part2": "PARTIE 2 - Utiliser revers fibo",
    "calcul8h.part1.desc":
      "Tout d'abord : \n Déterminez la direction du marché en utilisant la dernière bougie d'hier à 22h00, ce qui signifie que l'heure d'ouverture du marché est à 06h00 du matin. \n De 70 à 80 pour cent, cela nous donne la direction du marché de 6h00 à 12h00. \n Deuxièmement : \n Sachez quand le marché va rebondir ou fixez un objectif.",
    "calcul8.part2.desc":
      "Cette méthode peut vous sembler étrange \n Non traditionnelle ou ce que j'ai étudié \n Avant de discuter ici des ratios que chaque marché respecte grandement et de connaître les zones à partir desquelles il pourrait rebondir \n Il est facile pour un débutant de savoir comment le marché évolue et ce qu'il faut répéter de manière simple \n Données extraites de chaque marché sur deux ans",
    "chess.title": "MÉTHODE D'ÉCHECS",
    "chess.part1": "PARTIE 1 - Deux pics sont survenus mardi et jeudi",
    "chess.part2": "PARTIE 2 - Moment d'or",
    "chess.part1.desc":
      "Cette information vous bénéficiera de la méthode d'échecs en déterminant la direction du marché par un certain pourcentage, en identifiant les zones de retracement, et même plus que cela, en identifiant les vrais sommets et creux présents sur le marché.\nCela rend plus facile pour tout trader de voir le marché et élimine de nombreux problèmes",
    "chess.part2.desc":
      "Cette information vous bénéficiera grandement à tout moment, même lors de l'entrée de vos transactions. Il existe certains moments sur le marché que nous appelons moments d'or, pourquoi !? Ces moments sont les meilleures zones pour entrer et sortir des transactions et connaître facilement le prix et comme confirmation pour toutes les analyses. Par exemple, supposons que vous vous réveilliez le matin pour trader la paire GBPUSD à l'achat. Vous avez acheté à 08h00 du matin, mais le prix continue de baisser jusqu'à 09h30 et change sa direction d'achat, ou 09h30 est la confirmation que GBPUSD continuera sa tendance à la baisse. Entre 08h00 et 09h30, tout était une perte pour vous. Supposons également que vous connaissiez vraiment le moment d'or, qui est 09h30. Cela vous rendra, mais cela ne vous fera pas entrer dans une longue transaction à 08h00. Au contraire, vous attendrez jusqu'à 09h30 et découvrirez s'il s'agira d'un achat ou d'une vente",

    "onetoone.title": "ONE TO ONE",
    "onetoone.text":
      "À travers votre étude de la méthode des échecs, vous changez progressivement votre vision du marché, modifiez vos zones d'entrée et certaines des opportunités que vous pensiez apprécier.\nÀ ce stade, vous parlez d'un niveau bien plus élevé qu'auparavant.\nNous vous proposons plus de 20 classes:\n- Direction du marché de la plus petite à la plus grande échelle de temps\n- Structure réelle du marché\n- Zones d'entrée fiables\n- Le chemin des actualités et votre entrée avant et après.",

    "about.title": "À PROPOS DE NOUS",
    "about.text":
      "L'Académie de Trading Oumansour commence son parcours en 2020 dans le but de corriger toutes les erreurs et de clarifier la véritable vision des marchés mondiaux,\nPour inclure plus de 800 personnes dès sa première année,\nL'expansion de l'académie a commencé à se propager dans la plupart des pays arabes avec la stratégie qu'elle a élaborée en trois ans, appelée la méthode d'échecs\nCe qui a inspiré les gens à son sujet, c'est qu'elle ne dépasse pas moins de 10 pips en stop loss\nPlutôt, j'ai recueilli tous les points d'entrée présents sur le marché dans son état le plus et le moins dynamique\nCela ne s'est pas arrêté là, car elle a travaillé pour donner au trader la liberté d'entrer à tout moment, de sorte que lorsque vous approfondissez le chemin des échecs, vous approfondissez le mouvement réel du marché, puis vous comprenez quand vous devez entrer dans un mouvement correctif et le mouvement principal, car certains étudiants de l'académie en sont arrivés à ce stade d'entrer dans une transaction. Vendre, atteindre l'objectif, et entrer à nouveau dans une transaction d'achat\nVoici la différence et l'attraction. Dans la plupart des stratégies de trading, vous ne pouvez entrer que dans un seul trade. Avec de nombreuses complications et confirmations, contrairement à la méthode d'échecs, vos points d'entrée peuvent être aperçus dès le premier regard sur le marché. C'est ce qui a incité les étrangers à l'étudier chez nous.\nPlutôt, lui enseigner aux étrangers a joué un rôle important dans la confirmation de sa crédibilité\nLe nombre d'étudiants en 2024 dépassera les 8 mille personnes.",
    "founders.title": "FONDATEURS",
    "hft.title": "HFT",
    "hft.text":
      "Obtenez l'opportunité de relever les défis des firmes de trading via notre service spécialisé dans le Trading à Haute Fréquence (HFT). Nous offrons des services exceptionnels aux traders qui cherchent à naviguer avec succès les défis des firmes de trading et à entrer dans le monde du trading à haute fréquence.\nQu'est-ce que le Trading à Haute Fréquence ? Le Trading à Haute Fréquence est une stratégie de trading qui repose sur l'utilisation d'ordinateurs avancés et de logiciels pour exécuter des transactions à des vitesses incroyablement rapides sur les marchés financiers. Cette approche vous permet de tirer parti des petites différences de prix dans un laps de temps très court.\nSur notre site web, nous vous offrons le soutien et l'orientation nécessaires pour relever les défis des firmes de trading en utilisant des stratégies de trading à haute fréquence. Que vous soyez débutant dans ce domaine ou professionnel chevronné, notre équipe dévouée vous offre le soutien nécessaire pour atteindre vos objectifs de trading.\nRejoignez-nous dès aujourd'hui et bénéficiez de l'expertise et des compétences que nous offrons pour vous aider à réussir dans le monde du trading à haute fréquence.",
    "hft.whatpropfirms": "What prop firms we pass?",
    "hft.pricing": "Pricing",
    "hft.certif": "CERTIFICATES",
    "nonhft.title": "GESTION DE COMPTE",
    "nonhft.text":
      "Bénéficiez de notre service de gestion de comptes financés par des sociétés de capital-risque grâce à des conseillers experts (EAs) et obtenez l'opportunité d'atteindre vos objectifs de trading avec facilité et efficacité.\nNous proposons un service de gestion de compte financé par des sociétés de capital-risque utilisant des conseillers experts (EAs) pour les traders cherchant à réaliser des bénéfices de manière constante sur les marchés financiers.\nQu'est-ce que la gestion de compte financé par des sociétés de capital-risque à travers des conseillers experts (EAs) ?\nProp-firm funded account management through Expert Advisors (EAs) is a process that provides professional traders with the opportunity to manage trading accounts funded by investment firms using automated trading techniques.\nOn our website, we provide prop-firm funded account management services through Expert Advisors (EAs) with professionalism and reliability.\nWhether you're looking to improve your trading performance or achieve exceptional financial returns, our specialized team provides you with the support and guidance needed to achieve your goals.\nJoin us today and benefit from our expertise in prop-firm funded account management through Expert Advisors (EAs) to succeed in the financial markets.",
    "contact.title": "CONTACTEZ NOUS",
    "contact.name.placeholder": "Votre nom *",
    "contact.email.placeholder": "Votre email *",
    "contact.message.placeholder": "Votre messsage *",
    "contact.submit.text": "Envoyer",
    "copy.title": "COPY TRADING",
    "copy.herearesomeresults": "HERE ARE SOME SCREENS OF COPY TRADING",
    "hft.pay":
  "Veuillez noter que le paiement est requis avant de remplir le formulaire, et le challenge soit MT4 PAS MT5. Merci de votre coopération",
  },
  ar: {
    "nav.home": "الرئيسية",
    "nav.about": "من نحن",
    "nav.mentorship": "التوجيه",
    "nav.mentorship.calcul8h": "حساب 8 ساعات",
    "nav.mentorship.chessmethod": "طريقة الشطرنج",
    "nav.mentorship.onetoone": "جلسة فردية",
    "nav.challenges": "التحديات",
    "nav.copy": "Copy",
    "nav.solde": "Soldes",
    "nav.language": "اللغة",
    "results.title": "النتائج",
    "results.herearesomeresults": "هنا بعض النتائج لطلاب أكاديمية أومانسور",
    "feedbacks.title": "التعليقات",
    "calcul8h.title": "حساب 8 ساعات",
    "calcul8h.part1": "الجزء 1 - استعمال 8 ساعات",
    "calcul8h.part2": "revers fibo الجزء 2 - استعمال",
    "calcul8h.part1.desc":
      "أولاً: \n حدد اتجاه السوق باستخدام شمعة الأمس في الساعة 22:00، مما يعني وقت فتح السوق في الساعة 06:00 صباحًا. \n من 70 إلى 80 في المئة يعطينا اتجاه السوق من الساعة 6 صباحًا حتى الساعة 12 ظهرًا. \n ثانيًا: \n اعرف متى سترتد السوق أو قم بتحديد هدف.",
    "calcul8.part2.desc":
      "هذه الطريقة قد تبدو غريبة بالنسبة لك \n غير تقليدية أو ما درسته \n قبل أن نتحدث هنا عن النسب التي يحترمها كل سوق بشكل كبير ونعرف المناطق التي قد يرتد منها \n من السهل على المبتدئ أن يعرف كيف تتحرك السوق وما يجب تكراره بطريقة بسيطة \n البيانات المأخوذة من كل سوق على مدى سنتين",

    "chess.title": "طريقة الشطرنج",
    "chess.part1": "الجزء 1 - حدث ذروتان يوم الثلاثاء والخميس",
    "chess.part2": "الجزء 2 - الوقت الذهبي",
    "chess.part1.desc":
      "ستستفيد هذه المعلومات من طريقة الشطرنج من خلال تحديد اتجاه السوق بنسبة معينة، وتحديد مناطق الانعكاس، وحتى أكثر من ذلك، تحديد الذروات والقيعان الحقيقية الموجودة في السوق. هذا يجعل من السهل على أي تاجر رؤية السوق ويقضي الكثير من المشاكل",
    "chess.part2.desc":
      "ستستفيد هذه المعلومات كثيرًا في جميع الأوقات، حتى عند دخول صفقاتك. هناك أوقات معينة في السوق نسميها الأوقات الذهبية، لماذا ؟! هذه الأوقات هي أفضل المناطق لدخول والخروج من الصفقات ومعرفة السعر بسهولة وكتأكيد لجميع التحليلات. على سبيل المثال، لنفترض أنك استيقظت في الصباح لتتاجر في زوج GBPUSD للشراء. لقد اشتريت في الساعة 08:00 صباحًا، لكن السعر ما زال ينخفض حتى الساعة 09:30 ويغير اتجاهه للشراء، أو الساعة 09:30 هي تأكيد على أن GBPUSD سيواصل اتجاهه الهابط. بين الساعة 08:00 و 09:30، كان كل شيء خسارة بالنسبة لك. لنفترض أيضًا أنك تعرف حقًا الوقت الذهبي، والذي هو 09:30. هذا يجعلك، ولكن لن يجعلك تدخل في صفقة طويلة في الساعة 08:00. بدلاً من ذلك، ستنتظر حتى الساعة 09:30 وتعرف ما إذا كان سيكون شراءً أو بيعًا",

    "onetoone.title": "ONE TO ONE",
    "onetoone.text":
      "من خلال دراستك لأسلوب الشطرنج، تغير تدريجياً رؤيتك للسوق، وتغيير مناطق الدخول الخاصة بك، وبعض الحركات التي رأيتها وظننت أنها تعجبك.\nفي هذه المرحلة، تتحدث عن مستوى أعلى بكثير مما كان عليه من قبل.\nنقدم لك أكثر من 20 فصلاً:\n- اتجاه السوق من أصغر إطار زمني إلى أكبر إطار زمني\n- هيكل السوق الحقيقي\n- مناطق الدخول الموثوق بها\n- مسار الأخبار ودخولك قبل وبعد.\n",

    "about.title": "من نحن",
    "about.text":
      "تبدأ أكاديمية التداول Omansour رحلتها في عام 2020 بهدف تصحيح جميع الأخطاء وتوضيح الرؤية الحقيقية للأسواق العالمية،\nلتشمل أكثر من 800 شخص في السنة الأولى،\nبدأت توسيع الأكاديمية في الانتشار في معظم البلدان العربية بالاستراتيجية التي وضعتها في ثلاث سنوات، والتي تم تسميتها بطريقة الشطرنج\nما ألهم الناس به هو أنها لا تتجاوز أقل من 10 نقاط في وقف الخسارة\nبل، جمعت جميع نقاط الدخول الموجودة في السوق في حالتها الأكثر والأقل ديناميكية\nلم تتوقف هنا، حيث عملت على منح التاجر حرية الدخول في أي وقت، بحيث عندما تنغمس في طريقة الشطرنج، تنغمس في الحركة الحقيقية للسوق، ثم تفهم متى يجب أن تدخل في حركة تصحيحية والحركة الرئيسية، حيث وصل بعض طلاب الأكاديمية إلى هذه المرحلة من دخول صفقة. بيع، وضرب الهدف، والدخول في صفقة شراء مرة أخرى\nهنا الفرق والجاذبية. في معظم استراتيجيات التداول، يمكنك الدخول فقط في صفقة واحدة. مع العديد من التعقيدات والتأكيدات، على عكس طريقة الشطرنج، يمكن أن تظهر نقاط الدخول الخاصة بك منذ النظرة الأولى إلى السوق. هذا ما دفع الأجانب لدراستها من عندنا.\nبل، كان تعليمها للأجانب يلعب دورًا مهمًا في تأكيد مصداقيتها\nسيصل عدد الطلاب في عام 2024 إلى أكثر من 8 آلاف شخص.",
    "founders.title": "المؤسسون",
    "hft.title": "HFT",
    "hft.text":
      "احصل على فرصة لاجتياز تحديات الشركات الاستثمارية من خلال خدمتنا المتخصصة في التداول عالي التردد (HFT). نحن نقدم خدمات استثنائية للمتداولين الذين يهدفون إلى التنقل بنجاح في تحديات الشركات الاستثمارية والدخول إلى عالم التداول عالي التردد.\nما هو التداول عالي التردد؟ التداول عالي التردد هو استراتيجية تداول تعتمد على استخدام أجهزة الكمبيوتر المتقدمة والبرمجيات لتنفيذ صفقات بسرعات لا تصدق في الأسواق المالية. تتيح لك هذه الطريقة استغلال الفجوات السعرية الصغيرة في فترة زمنية قصيرة جدًا.\nعلى موقعنا الإلكتروني، نقدم لك الدعم والإرشاد اللازمين لاجتياز تحديات الشركات الاستثمارية باستخدام استراتيجيات التداول عالية التردد. سواء كنت مبتدئًا في هذا المجال أو محترفًا متمرسًا، يقدم لك فريقنا المتخصص الدعم اللازم لتحقيق أهدافك في التداول.\nانضم إلينا اليوم واستفد من الخبرة والمهارات التي نقدمها لمساعدتك على النجاح في عالم التداول عالي التردد.",
    "hft.whatpropfirms": "What prop firms we pass?",
    "hft.pricing": "Pricing",
    "hft.certif": "CERTIFICATES",
    "hft.pay": "يرجى ملاحظة أن الدفع مطلوب قبل ملء النموذج و التحدي يكون MT4 وليس  MT5. شكراً لتعاونكم",
    "nonhft.title": "إدارة الحساب",
    "nonhft.text":
      "استفد من خدمتنا في إدارة الحسابات الممولة من شركات رأس المال من خلال المستشارين الخبراء (EAs) واحصل على الفرصة لتحقيق أهداف التداول الخاصة بك بسهولة وكفاءة.\nنحن نقدم خدمة إدارة الحسابات الممولة من شركات رأس المال باستخدام المستشارين الخبراء (EAs) للمتداولين الذين يسعون إلى تحقيق أرباح متسقة في الأسواق المالية.\nما هي إدارة الحسابات الممولة من شركات رأس المال من خلال المستشارين الخبراء (EAs)؟\nإدارة الحسابات الممولة من شركات رأس المال من خلال المستشارين الخبراء (EAs) هي عملية توفير فرصة للمتداولين المحترفين لإدارة حسابات التداول الممولة من قبل شركات الاستثمار باستخدام تقنيات التداول الآلي.\nعلى موقعنا الإلكتروني، نقدم خدمات إدارة الحسابات الممولة من شركات رأس المال من خلال المستشارين الخبراء (EAs) بمهنية وموثوقية.\nسواء كنت تسعى لتحسين أدائك في التداول أو تحقيق عوائد مالية استثنائية، فإن فريقنا المتخصص يوفر لك الدعم والإرشاد اللازمين لتحقيق أهدافك.\nانضم إلينا اليوم واستفد من خبرتنا في إدارة الحسابات الممولة من شركات رأس المال من خلال المستشارين الخبراء (EAs) للنجاح في الأسواق المالية.",
    "contact.title": "راسلونا",
    "contact.name.placeholder": "اسمك *",
    "contact.email.placeholder": "بريدك الالكتروني *",
    "contact.message.placeholder": "رسالتك *",
    "contact.submit.text": "إرسال",
    "copy.title": "COPY TRADING",
    "copy.herearesomeresults": "HERE ARE SOME SCREENS OF COPY TRADING",
  },
};

export default messages;
