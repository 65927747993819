import {
  faChevronLeft,
  faChevronRight,
  faHandPaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import disclaimer from "../images/disclaimer.png";

function Copy() {
  const registrationLink =
    "https://docs.google.com/forms/d/1x5syy8VQWg9at9ipqQ7RHxPu5Nl62QwQjCy2vSId47M/edit";
  const registerLinkConditon =
    "https://go.ultimamarkets.com/visit/?bta=35707&brand=ultimarketsaffiliates";
  const telegramGroupLink = "https://t.me/OumansourChallenges";
  const images = [
    require("../images/copy/2.jpg"),
    require("../images/copy/3.jpg"),
    require("../images/copy/4.jpg"),
    require("../images/copy/5.jpg"),
    require("../images/copy/6.jpg"),
    require("../images/copy/7.jpg"),
    require("../images/copy/8.jpg"),
    require("../images/copy/9.jpg"),
    require("../images/copy/10.jpg"),
    require("../images/copy/11.jpg"),
    require("../images/copy/12.jpg"),
    require("../images/copy/13.jpg"),
    require("../images/copy/14.jpg"),
    require("../images/copy/15.jpg"),
  ];
  function previous() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "left", 25, 100, 10);
    }
  }
  function next() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "right", 25, 100, 10);
    }
  }
  function sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  return (
    <div className="results-container">
      <div className="result-section results-s1">
        <div className="overlay3">
          <h1 className="section-head-title">
            <FormattedMessage id="copy.title" />
          </h1>
          <div className="copy-desc">
            <p>
              Copy Trading is a form of trading where individuals can
              automatically copy positions opened and managed by another trader
              or group of traders. It's a method that allows less experienced
              traders to benefit from the expertise of more seasoned traders
              without needing to make their own trading decisions.
            </p>
            <h2>Conditions :</h2>
            <ul>
              <li>
                Register{" "}
                <Link
                  to={registerLinkConditon}
                  className="register-btn-condition"
                  target="_blank"
                >
                  here
                </Link>
              </li>
              <li>Deposit at least 250$</li>
              <li>
                Send screenshots of the 2 previous steps in the Telegram group{" "}
                <Link
                  to={telegramGroupLink}
                  className="register-btn-condition"
                  target="_blank"
                >
                  oumansourchallenges
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="chess-register">
        <img src={disclaimer} alt="disclaimer" className="disclaimer" />
        <p className="payement">
          If your capital exceeds 10.000$ , please join the white list
        </p>
        <Link to={registrationLink} target="_blank" className="register-btn">
          <FontAwesomeIcon icon={faHandPaper} />
          Join The Wait List
        </Link>
      </div>
      <div className="result-section results-s1">
        <div className="overlay2">
          <p className="results-slogan">
            <FormattedMessage id="copy.herearesomeresults" />
          </p>
          <div className="slide-container">
            <button onClick={previous} className="nav-btn left-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronLeft} />
            </button>
            <div className="silder" id="slider">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`imageslider ${index}`}
                  className="imageSlider"
                />
              ))}
            </div>
            <button onClick={next} className="nav-btn right-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Copy;
