import React from "react";
import { FormattedMessage } from "react-intl";
import propfirms from "../images/propfirms.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faHandPaper,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import disclaimer from "../images/disclaimer.png";

function HFT() {
  const registrationLink =
    "https://docs.google.com/forms/d/1MgczZHRJgK3NpPHLRu-PfRkdR0ysVh0yStoKDUiL4JU/edit";
  const telegramGroupLink = "https://t.me/OumansourChallenges";
  const images = [
    require("../images/certifhft/1.jpg"),
    require("../images/certifhft/2.jpg"),
    require("../images/certifhft/3.jpg"),
    require("../images/certifhft/4.jpg"),
    require("../images/certifhft/5.jpg"),
    require("../images/certifhft/6.jpg"),
    require("../images/certifhft/7.jpg"),
    require("../images/certifhft/8.jpg"),
    require("../images/certifhft/9.jpg"),
  ];
  function previous() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "left", 25, 100, 10);
    }
  }
  function next() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "right", 25, 100, 10);
    }
  }
  function sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  return (
    <div className="results-container">
      <div className="result-section calcul8h-s1">
        <div className="overlay2 overlay-chess overlay-about">
          <h1 className="section-head-title">
            <FormattedMessage id="hft.title" />
          </h1>
          <p className="about-text">
            <FormattedMessage id="hft.text" />
          </p>
        </div>
      </div>
      <div className="chess-register">
        <img src={disclaimer} alt="disclaimer" className="disclaimer" />
        <p className="payement">
          <FormattedMessage id="hft.pay" />
          <br />
          for any questions or payments please send a message in telegram group{" "}
          <Link
            to={telegramGroupLink}
            className="register-btn-condition"
            target="_blank"
          >
            oumansourchallenges
          </Link>
        </p>
        <Link to={registrationLink} target="_blank" className="register-btn">
          <FontAwesomeIcon icon={faHandPaper} />
          Join The Wait List
        </Link>
      </div>
      <div id="hft-prices" className="result-section calcul8h-s2">
        <div className="overlay2 overlay-chess overlay-about">
          <div className="propfirms-container">
            <div className="propfirms">
              <h1 className="section-head-title section-head-title-2">
                <FormattedMessage id="hft.whatpropfirms" />
              </h1>
              <img src={propfirms} alt="propfirms" className="propfirms-img" />
            </div>
            <div className="line" />
            <div className="pricing">
              <h1 className="section-head-title section-head-title-2">
                <FormattedMessage id="hft.pricing" />
              </h1>
              <table className="pricing-table">
                <tr>
                  <td>10K</td>
                  <td>70 $</td>
                </tr>
                <tr>
                  <td>25K</td>
                  <td>105 $</td>
                </tr>
                <tr>
                  <td>50K</td>
                  <td>175 $</td>
                </tr>
                <tr>
                  <td>100K</td>
                  <td>245 $</td>
                </tr>
                <tr>
                  <td>200K</td>
                  <td>300 $</td>
                </tr>
                <tr>
                  <td>300K</td>
                  <td>300 $</td>
                </tr>
                <tr>
                  <td>500K</td>
                  <td>300 $</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="result-section results-s1">
        <div className="overlay2">
          <h1 className="section-head-title">
            <FormattedMessage id="hft.certif" />
          </h1>
          <div className="slide-container">
            <button onClick={previous} className="nav-btn left-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronLeft} />
            </button>
            <div className="silder" id="slider">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`imageslider ${index}`}
                  className="imageSlider"
                />
              ))}
            </div>
            <button onClick={next} className="nav-btn right-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HFT;
