import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/main.css";
import Home from "./components/Home";
import { IntlProvider } from "react-intl";
import { useEffect, useState } from "react";
import messages from "./common/translation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calcul8h from "./components/Calcul8h";
import Navbar from "./common/Navbar";
import Chess from "./components/Chess";
import OneToOne from "./components/OneToOne";
import About from "./components/About";
import HFT from "./components/HFT";
import NONHFT from "./components/NONHFT";
import Contact from "./components/Contact";
import Copy from "./components/Copy";

function App() {
  const [locale, setLocale] = useState(
    localStorage.getItem("langSaved") !== null
      ? localStorage.getItem("langSaved")
      : navigator.language && navigator.language.split(/[-_]/)[0] === "fr"
      ? "fr"
      : "en"
  );

  function handleLangChange(value) {
    setLocale(value);
    localStorage.setItem("langSaved", value);
    if (value === "en") {
      toast.success("Language has been changed");
    } else if (value === "fr") {
      toast.success("La langue a été changée");
    } else {
      toast.success("لقد تم تغيير اللغة");
    }
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? (
    <div className="spinner-container">
      <div class="spinner" />
    </div>
  ) : (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ToastContainer position="bottom-right" />
        <Navbar locale={locale} handleChangeLocale={handleLangChange} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clacul8h" element={<Calcul8h />} />
          <Route path="/chessmethod" element={<Chess />} />
          <Route path="/onetoone" element={<OneToOne />} />
          <Route path="/about" element={<About />} />
          <Route path="/hft" element={<HFT />} />
          <Route path="/nonhft" element={<NONHFT />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/copytrading" element={<Copy />} />
        </Routes>
      </IntlProvider>
    </BrowserRouter>
  );
}

export default App;
