import React from "react";
import { FormattedMessage } from "react-intl";

function Feedbacks() {
  return (
    <div className="results-container">
      <div className="result-section feedbacks-s1">
        <div className="overlay2">
          <h1 className="section-head-title">
            <FormattedMessage id="feedbacks.title" />
          </h1>
          <div className="feedbacks-video">
            <iframe
              title="feedbacks"
              src="https://www.youtube.com/embed/_VyLd-geKP4"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedbacks;
