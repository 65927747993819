import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";

function Results() {
  const images = [
    require("../images/results/1.jpg"),
    require("../images/results/2.jpg"),
    require("../images/results/3.jpg"),
    require("../images/results/4.jpg"),
    require("../images/results/5.jpg"),
    require("../images/results/6.jpg"),
    require("../images/results/7.jpg"),
    require("../images/results/8.jpg"),
    require("../images/results/9.jpg"),
    require("../images/results/10.jpg"),
    require("../images/results/11.jpg"),
    require("../images/results/12.jpg"),
    require("../images/results/13.jpg"),
    require("../images/results/14.jpg"),
    require("../images/results/15.jpg"),
    require("../images/results/16.jpg"),
    require("../images/results/17.jpg"),
    require("../images/results/18.jpg"),
    require("../images/results/19.jpg"),
    require("../images/results/20.jpg"),
    require("../images/results/21.jpg"),
    require("../images/results/22.jpg"),
    require("../images/results/23.jpg"),
  ];
  function previous() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "left", 25, 100, 10);
    }
  }
  function next() {
    const slider = document.getElementById("slider");
    if (slider) {
      sideScroll(slider, "right", 25, 100, 10);
    }
  }
  function sideScroll(element, direction, speed, distance, step) {
    var scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  return (
    <div className="results-container">
      <div className="result-section results-s1">
        <div className="overlay2">
          <h1 className="section-head-title">
            <FormattedMessage id="results.title" />
          </h1>
          <p className="results-slogan">
            <FormattedMessage id="results.herearesomeresults" />
          </p>
          <div className="slide-container">
            <button onClick={previous} className="nav-btn left-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronLeft} />
            </button>
            <div className="silder" id="slider">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`imageslider ${index}`}
                  className="imageSlider"
                />
              ))}
            </div>
            <button onClick={next} className="nav-btn right-btn">
              <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
