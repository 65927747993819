import { faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function NONHFT() {
  const registrationLink =
    "https://docs.google.com/forms/d/1IK74HLg65bLQgRowZoCAy0KYOo75G3Qo8s0jVk7VT_A/edit";
  return (
    <div className="results-container">
      <div className="result-section calcul8h-s1">
        <div className="overlay2 overlay-chess overlay-about">
          <h1 className="section-head-title">
            <FormattedMessage id="nonhft.title" />
          </h1>
          <Link
            to={registrationLink}
            target="_blank"
            className="register-btn register-btn-floatant"
          >
            <FontAwesomeIcon icon={faHandPaper} />
            Join The Wait List
          </Link>
          <p className="about-text">
            <FormattedMessage id="nonhft.text" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default NONHFT;
